$grey: rgb(233, 233, 233);
$black: rgb(0, 21, 36);
$white: rgb(219, 219, 219);
$cardBorderRadius: 22px;
::selection {
  background-color: #6C777F;
  color: $white;
}
.presov {
  animation: presov_animation 1s ease;
  @keyframes presov_animation {
    from {
      transform: translateX(500px) translateY(-500px);
      opacity: 0;
    }
    to {
      transform: translateX(0px) translateY(0px);
      opacity: 1;
    }
  }
}
.kosice {
  animation: kosice_animation 1.2s ease;
  @keyframes kosice_animation {
    from {
      transform: translateX(500px) translateY(500px);
      opacity: 0;
    }
    to {
      transform: translateX(0px) translateY(0px);
      opacity: 1;
    }
  }
}
.banska_bystrica {
  animation: banska_bystrica_animation 1.6s ease;
  @keyframes banska_bystrica_animation {
    from {
      transform: translateX(-100px) translateY(500px);
      opacity: 0;
    }
    to {
      transform: translateX(0px) translateY(0px);
      opacity: 1;
    }
  }
}
.zilina {
  animation: zilina_animation 1.8s ease;
  @keyframes zilina_animation {
    from {
      transform: translateX(100px) translateY(-500px);
      opacity: 0;
    }
    to {
      transform: translateX(0px) translateY(0px);
      opacity: 1;
    }
  }
}
.trencin {
  animation: trencin_animation 1.6s ease;
  @keyframes trencin_animation {
    from {
      transform: translateX(-300px) translateY(-500px);
      opacity: 0;
    }
    to {
      transform: translateX(0px) translateY(0px);
      opacity: 1;
    }
  }
}
.zilina {
  animation: zilina_animation 1.4s ease;
  @keyframes zilina_animation {
    from {
      transform: translateX(-300px) translateY(500px);
      opacity: 0;
    }
    to {
      transform: translateX(0px) translateY(0px);
      opacity: 1;
    }
  }
}
.trnava {
  animation: trnava_animation 1.4s ease;
  @keyframes trnava_animation {
    from {
      transform: translateX(-500px) translateY(100px);
      opacity: 0;
    }
    to {
      transform: translateX(0px) translateY(0px);
      opacity: 1;
    }
  }
}
.bratislava {
  animation: bratislava_animation 1.7s ease;
  @keyframes bratislava_animation {
    from {
      transform: translateX(-500px) translateY(200px);
      opacity: 0;
    }
    to {
      transform: translateX(0px) translateY(0px);
      opacity: 1;
    }
  }
}
@keyframes body_animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
body {

  padding: 0;
  margin: 0;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 990px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media only screen and (max-width: 600px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  /* max-width: 1800px; */
  font-family: 'kanit regular', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: $grey;
}
.body_wrap {
  animation: body_animation 0.8s ease;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: $grey;
}
::-webkit-scrollbar {
  display: none;
}
.content_wrap {
  display: flex;
  gap: 15px;
}
header {
  max-width: 1800px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;

  .mobile_view_wrap {
    display: none;
  }
  @media only screen and (max-width: 800px) {
    .header_list, .main_logo_wrap, .action_bar_wrap  {
      display: none;
    }
    .mobile_view_wrap_open {/*
      height: 300px !important;
      width: 80%; */
      /* width: 80% !important;
      height: 300px !important; */
      animation: menu_animation 0s ease;
      @keyframes menu_animation {
        from {
          width: 90px;
          height: 90px;
        }
        to {
          width: 250px;
          height: 250px;
        }
      }
    }
    .mobile_view_wrap_open {
      width: 250px;
      height: 250px;
      .hamburger {
        .icon {
          transform: rotate(90deg) !important;
        }
      }
    }
    .mobile_view_wrap_closed {
      width: 90px;
      height: 90px;
      animation: menu_close_animation 0s ease;
      @keyframes menu_close_animation {
        from {
          width: 250px;
          height: 250px;
        }
        to {
          width: 90px;
          height: 90px;
        }
      }

      .home_icon {
        bottom: 200px !important;
        right: 200% !important;
      }
      .about_us_icon {
        bottom: 200px !important;
        right: 200% !important;
      }
      .service_icon {
        bottom: 200px !important;
        right: 200% !important;
      }
    }
    .mobile_view_wrap_closed_first_render {
      width: 90px;
      height: 90px;

      .home_icon {
        transition: 0s !important;
        bottom: 200px !important;
        right: 200% !important;
      }
      .about_us_icon {
        transition: 0s !important;
        bottom: 200px !important;
        right: 200% !important;
      }
      .service_icon {
        transition: 0s !important;
        bottom: 200px !important;
        right: 200% !important;
      }
    }
    .mobile_view_wrap {
      display: flex ;
      align-items: center;
      position: fixed;
      right: 0;
      bottom: 0;
      border-radius: 100% 0px 0px 0px;
      /* width: 100%; */
      background-color: rgba($black, 0.9);
      box-shadow: 0px 0px 50px #ffffff;
      z-index: 999 !important;
      overflow: hidden;


      .home_icon {
        transition: 0.6s;
        display: flex;
        align-items: center;
        gap: 10px;
        color: $white;
        position: absolute;
        bottom: 30px;
        right: 45%;
        white-space: nowrap;
      }
      .about_us_icon {
        transition: 0.6s;
        display: flex;
        align-items: center;
        gap: 10px;
        color: $white;
        position: absolute;
        bottom: 95px;
        right: 37%;
        white-space: nowrap;
      }
      .service_icon {
        transition: 0.6s;
        display: flex;
        align-items: center;
        gap: 10px;
        color: $white;
        position: absolute;
        bottom: 160px;
        right: 12%;
        white-space: nowrap;
      }

      .hamburger {
        .navigation_icon {
          z-index: 500 !important;
          /* transform: translateX(100%) translateY(50%); */
        }
        padding: 0px 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        img {
          width: 100px;
          filter: drop-shadow(0px 0px 20px #ffffff);
        }
        .icon {
          transition: 1s;
          position: absolute;
          bottom: 15px;
          right: 15px;
          transform: rotate(-90deg) /* translateX(-10px) translateY(10px) */;
          color: $white;
          z-index: 999 !important;
        }
      }
    }
    animation: none !important;
  }
  @keyframes header_animation {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: header_animation 0.5s ease-out;
  .header_list {
    ul {
      list-style-type: none;
      display: flex;
      gap: 15px;
      padding: 0;
      li {
        font-weight: 700;
        cursor: pointer;
        transition: 0.3s;
        padding: 3px 10px;
        border-radius: 2px;
        &:hover {
          color: $black;
          border-radius: 6px;
          box-shadow: 0px 5px 5px rgba($black, 0.8);
        }
      }
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
  @media only screen and (max-width: 990px) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .hamburger_wrap {
    z-index: 50 !important;
    border-radius: 26px;
    background-color: $black;
    display: flex;
    padding: 8px 8px;
    align-items: center;
    width: 110px;
    gap: 5px;
    color: $white;
    cursor: pointer;
    span {
      font-size: 18px;
    }
    .hamburger_icon {
      transition: 0.3s;
      border-radius: 16px 5px 5px 16px;
      padding: 0px 4px;
    }
    &:hover {

      .hamburger_icon {
        background-color: $white;
        color: $black;
      }
    }
  }
  .main_logo_wrap {
    img {
      max-width: 100px;
    }
  }
  .action_bar_wrap {
    button {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      gap: 5px;
      border-radius: 26px;
      cursor: pointer;
      border: 2px solid $black;
      span {
        font-size: 18px;
        padding-right: 8px;
      }
      .phone_icon {
        transition: 0.3s;
        padding: 5px 5px;
        border-radius: 16px 5px 5px 16px;
        padding-left: 8px;
      }
      color: $white;
      background-color: $black;
      &:hover {
        .phone_icon {
          background-color: $white;
          color: $black;
        }
      }
      &:active {
        border: 2px solid $black;
      }
    }
  }
}
.box_shadow {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.expanded_menu {
 /*  transition: 0.3s; */
 background-color: white;
  ol{
    padding: 0;
    list-style-type: none;
    gap: 10px;
    display: flex;
    flex-direction: column;
    width: 220px;
    li {
      white-space: nowrap !important;
      color: $black;
      cursor: pointer;
      transition: 0.3s;
      display: flex;
      align-items: center;
      gap: 10px;
      .icon {
        position: relative;
      }

      &:hover {
        transform: translateX(15px);
      }
    }
  }

  padding: 16px;
  border-radius: $cardBorderRadius;
}
.no_animation {
  animation: none !important;
  transition: none !important;
}
.hidden_menu {
  transition: 0.3s;
  max-width: 0px;
  overflow: hidden;
  max-height: 0px;
  /* transform: translateY(-55px); */
  z-index: 1 !important;
  animation: animation_close 0.5s ease-out;
}

.hidden_menu_first_load {
  max-width: 0px;
  overflow: hidden;
  max-height: 0px;
  /* transform: translateY(-55px); */
  z-index: 1 !important;
  /* animation: none !important; */
}

.visible_menu {
  overflow: hidden;
  transition: 0.3s;
  height: auto;
  width: 220px !important;
  max-height: 150px;
  transform: translateY(0px);
  z-index: 1 !important;
  animation: animation_open 1.5s ease-out;
}
@keyframes animation_open {
  0% {
    max-height: 0px;
    max-width: 0px;
    /* transform: translateY(-55px); */
  }
  50% {
    max-height: 150px;
    max-width: 220px;
    /* transform: translateY(0px); */
  }
}

@keyframes animation_close {
  0% {
    /* transform: translateY(0px); */
    max-width: 220px;
    max-height: 150px;
    overflow: hidden;
  }
  50% {
    /* transform: translateY(-55px); */
    overflow: hidden;
    width: 0px !important;
    min-width: 0px;
    max-height: 0px;

    /* max-width: 0px !important;
    overflow: hidden;
    max-height: 0px;
    transform: translateY(-55px); */
  }
}
.intro_card_mobile {

  @media only screen and (min-width: 801px) {
    display: none !important;
  }
  .intro_card_header_wrap {
    background-color: #001524;
    top: 0;
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 999;

    @media only screen and (max-width: 340px) {
      height: 150px;
    }

    .intro_card_header_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;

      @media only screen and (max-width: 340px) {
        flex-direction: column;
        gap: 10px;
      }
      .logo {
        width: 130px;
        img {
          width: 100%;
        }
      }
      .cta_card {
        color: $white;
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 5px;
        border: 1px solid $white;
        border-radius: 16px;
        padding: 15px 15px;
        transition: 0.3s;
        .icon {
          color: #ffffff;
        }
        &:hover {
          .icon {
            color: #001524;
          }
          background-color: $white;
          color: $black;
        }
        &:active {
          .icon {
            color: #001524;
          }
          background-color: $white;
          color: $black;
        }
      }
    }

  }
  .intro_card_mobile_content {
    margin-top: 80px;
    @media only screen and (max-width: 340px) {
      margin-top: 150px;
    }
    .image_wrap {
      background-color: #001524;
      color: $white;
      padding: 0px 0px;
      height: 55%;
      position: relative;
      h1 {
        position: absolute;
        bottom: 20px;
        left: 10px;
        font-size: 38px;
      }
      .img {
        opacity: 0.4;
        position: absolute;
        width: 100vw;
        height: 100%;
        background-image: url("../images/bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .text_wrap {

      padding: 0px 10px;
      p:nth-child(3) {
        margin-top: 20px;
      }
    }
    height: 100vh;
  }
  height: 100vh;
}

.intro_card {
  max-width: 1800px;
  margin: 0 auto;
  min-height: 80vh;
  overflow: hidden;

  @media only screen and (max-width: 800px) {
    display: none;
  }
  .intro_card_content {
    margin-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .logo {
      display: none;
      margin-top: 20px;
    }
    @media only screen and (max-width: 990px) {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 60px;
      .logo {
        display: flex;
        justify-content: center;
        width: 100%;
        img {
          /* max-width: 95%; */
          width: calc(100% - 0px);
          @media only screen and (max-width: 550px) {
            width: 90%;
          }

        }
        animation: intro_logo_animation 0.8s ease;
        @keyframes intro_logo_animation {
          0% {
            opacity: 0;
            transform: translateY(-800px);
          }

          100% {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }
    }
    @media only screen and (max-width: 550px) {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  svg{

    @media only screen and (max-width: 990px) {
      display: none;
    }
    position: absolute;
    animation: intro_card_animation 0.8s ease;
    @keyframes intro_card_animation {
      0% {
        transform: scale(0.5);
        transform-origin: 50% 0%;
      }

      100% {
        transform: scale(1);
        transform-origin: 50% 0%;
      }
    }
  }
  .background_image {
    opacity: 0.4;
    object-fit: cover;
  }
  width: calc(100% - 80px);
  @media only screen and (max-width: 550px) {
      width: 100% !important;
  }
  /* background-color: orange; */
  /* background-image: url("../images/card.png");
  background-size: 100% 100%;
  background-repeat: no-repeat; */
  /* padding: 5px 35px; */
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
  p {
    display: none;
    @media only screen and (max-width: 990px) {
      display: flex;
      /* padding-left: 15px;
      padding-right: 15px; */
      animation: main_paragraph_animation 1.5s ease;
      @keyframes main_paragraph_animation {
        0% {
          opacity: 0;
          transform: translateX(-300px);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
    @media only screen and (max-width: 550px) {
      padding: 0px 15px;
    }
  }
  h1 {
    position: absolute;
    bottom: 0;
    left: 0px;
    @media only screen and (max-width: 990px) {
      top: 45px;
      /* left: 15px; */
      width: 100%;
      position: relative;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 45px;
      animation: main_title_phone_animation 1s ease;
    }
    @media only screen and (max-width: 550px) {
      padding: 0px 15px;
    }
    padding: 0;
    margin: 0;
    font-size: 56px;
    line-height: 62px;
    font-weight: 900;
    color: $black;
    animation: main_title_animation 1s ease;
    @keyframes main_title_animation {
      0% {
        opacity: 0;
        transform: translateX(-300px) translateY(200px);
      }

      100% {
        opacity: 1;
        transform: translateX(0) translateY(0);
      }
    }
    @keyframes main_title_phone_animation {
      0% {
        opacity: 0;
        transform: translateX(-300px);
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  .cta_card {

    @media only screen and (max-width: 800px) {
      display: none;
    }
    @media only screen and (max-width: 990px) {
      position: relative;
      margin-left: 15px;
      border-radius: 18px;
      margin-top: -45px;
      /* margin-top: 55px; */
    }
    @media only screen and (max-width: 320px) {
      width: 280px;
    }
    @keyframes main_cta_animation {
      0% {
        opacity: 0;
        transform: translateX(0px) translateY(-80px);
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
    @keyframes main_cta_phone_animation {
      0% {
        opacity: 0;
        transform: translateX(-800px);
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
    animation: main_cta_animation 1s ease;
    @media only screen and (max-width: 990px) {
      animation: main_cta_phone_animation 1.5s ease;
    }
    position: absolute;
    bottom: 0;
    right: 0px;
    height: 80px;
    width: 320px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    transition: 0.3s;
    align-items: center;
    cursor: pointer;
    color: $white;
    background-color: $black;
    /* filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3)); */
    span {
      font-weight: bold;
      font-size: 26px;
      text-align: center;
    }
    &:hover {
      transform: scale(1.05) translateX(-10px) translateY(-10px);
      border-radius: 18px;
    }
  }
}


.service_card {
  margin-top: 20px;
  background-color: $black;
  /* color: $white; */
  /* background-color: $black; */
  min-height: 100vh;
  .service_title {
    padding-top: 70px;
    padding-bottom: 30px;
    font-size: 32px;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    animation: service_title_animation 0.8s ease;
    @keyframes service_title_animation {
      from {
        transform: translateY(-80px);
        opacity: 0;
      }
      to {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }
  .service_card_wrap {
    padding:  20px 20px;
    position: relative;
    text-align: center;
    color: $white;
    border-radius: 26px;
    z-index: 500 !important;
    .service_content_wrap {
      animation: service_wrap_animation 1s ease;
      /* border-top: 2px solid $white; */
      text-align: start;
      /* padding-top: 30px; */
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }
    .service_descr {
      margin-bottom: 30px;
    }
    .service_element {
      margin-bottom: 50px;
      display: flex;
      @media only screen and (max-width: 990px) {

        flex-direction: column;
        .description_wrap {
          text-align: center;
        }
      }
      padding: 0px 40px;
      gap: 30px;
      .img_wrap {
        position: relative;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 990px) {
          justify-content: center;
        }
        img {
          @media only screen and (max-width: 990px) {
            left: 40%;
            width: 50%;
            max-width: 150px;
          }
          @media only screen and (max-width: 800px) {
            left: 35%;
            width: 50%;
            max-width: 150px;
          }
          top: 0;
          left: -50%;
          width: 150%;
          position: absolute;
        }
      }

      &:nth-child(1) {
        border-top: 2px solid $white;
        @media only screen and (max-width: 990px) {
          border: none;
        }
        animation-duration: 0.8s;
      }
      &:nth-child(2) {
        animation-duration: 1.2s;
      }
      &:nth-child(3) {
        animation-duration: 1.6s;
      }
      &:nth-child(4) {
        animation-duration: 2s;
      }
      &:nth-child(even) {
        animation-name: service_element_animation;
        animation-timing-function: ease;
      }
      &:nth-child(odd) {
        animation-timing-function: ease;
        animation-name: service_element_right_animation;
      }
      @keyframes service_element_animation {
        from {
          transform: translateX(-1000px);
          opacity: 0;
        }
        to {
          transform: translateY(0px);
          opacity: 1;
        }
      }
      @keyframes service_element_right_animation {
        from {
          transform: translateX(1000px);
          opacity: 0;
        }
        to {
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }
    .service_element_right {
      flex-direction: row-reverse;
      @media only screen and (max-width: 990px) {
        flex-direction: column;
      }
    }
  }
  .service_card_background {
    background-color: $black;
    position: absolute;
    min-height: 80vh;
    z-index: 1 !important;
    width: 100vw;
    left: 0;
  }
}



.projects_card {
  max-width: 1200px;
  min-height: 500px;
  margin: 0 auto;
  padding: 80px 40px;
  @media only screen and (max-width: 990px) {
    padding: 80px 0px;
    margin-top: 20px;
  }
  h1 {
    @media only screen and (max-width: 990px) {
      text-align: center;
    }
    animation: projects_card_title_animation 1s ease;
    @keyframes projects_card_title_animation {
      from {
        transform: translateX(-1000px);
        opacity: 0;
      }
      to {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }
  .projects_wrap {
    display: flex;
    @media only screen and (max-width: 990px) {
      flex-direction: column;
    }
    /* gap: 30px; */
    /* margin-bottom: 30px; */
    justify-content: space-between;
    .project_element{
      @keyframes projects_card_element_animation {
        from {
          transform: translateY(60px);
          opacity: 0;
        }
        to {
          transform: translateX(0px);
          opacity: 1;
        }
      }
      animation-name: projects_card_element_animation;
      animation-timing-function: ease;
      &:nth-child(1) {
        width: 33%;
        animation-duration: 1s;
      }
      &:nth-child(2) {
        width: 33%;
        animation-duration: 1.5s;
      }
      &:nth-child(3) {
        width: 33%;
        animation-duration: 2s;
      }
      @media only screen and (max-width: 990px) {
        width: 80% !important;
        margin: 0 auto;
        margin-bottom: 30px;
      }
      .project_element_img {
        /* background-color: $white; */
        /* box-shadow: 0px 0px 10px $black; */
        border-radius: 16px;
        /* min-width: 300px; */
        max-height: 250px;
        /* padding: 20px 20px; */
        min-height: 150px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          object-fit: cover;
          /* border-radius: 12px; */
        }
      }
    }
  }
}

.map_card {
 /*  margin-top: 80px; */

  /* max-width: 1800px; */
  /* margin: 0 auto; */
  padding-left: 40px;
  padding-right: 40px;
  background-color: $black;

  .map_card_wrap {
    overflow: hidden;
    .map_card_descr {
    font-size: 18px;
      font-weight: 500;
      padding-bottom: 45px;
      text-align: center;
      animation: mam_descr_animation 1.2s ease;
      @keyframes mam_descr_animation {
        from {
          transform: translateY(40px);
          opacity: 0;
        }
        to {
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }
    padding:  0px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    justify-content: center;
    align-items: center;
    color: $white;
    z-index: 500 !important;
    svg {
    max-width: 920px;
    padding: 0px 150px;
    @media only screen and (max-width: 990px) {
      max-width: 300px;
      padding: 0px 0px;
      max-height: 320px;
      width: 100%;
    }
    }
  }
  .map_card_background {
    background-color: $black;
    position: absolute;
    min-height: 80vh;
    z-index: 1 !important;
    width: 100vw;
    left: 0;
  }
  @media only screen and (max-width: 700px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.contact_us_banner {
 /*  min-height: 40vh; */

  /* overflow-y: hidden !important; */
  /* min-height: 0vh; */
  transition: 0.5s;
  .contact_us_banner_light_theme {
    background-color: $white !important;
    color: $black !important;
    .icon {

    }
    /* background-color: $white !important; */
  }
  .contact_us_banner_dark_theme {
    background-color: $black !important;
  }
  .contact_us_banner_content {
    color: $white;
    z-index: 500 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 0px;
    .title {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
    }
    .call_us {
      font-size: 30px;
      font-weight: 400;
      padding-top: 25px;
      text-align: center;
    }
    .social {
      padding-top: 25px;
      display: flex;
      gap: 20px;
      min-height: 50px;
      .icon {
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
        @keyframes icon_animation {
          from {
            transform: translateY(40px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        &:nth-child(1) {
          animation: icon_animation 0.4s ease;
        }
        &:nth-child(2) {
          animation: icon_animation 0.6s ease;
        }
        &:nth-child(3) {
          animation: icon_animation 0.8s ease;
        }
        &:nth-child(4) {
          animation: icon_animation 1s ease;
        }
      }
    }
  }
  .contact_us_banner_bg_image {
    /* background-color: $black; */
    position: absolute;
    min-height: 40vh;
    z-index: 1 !important;
    width: 100vw;
    left: 0;
  }
}
footer {
  @media only screen and (max-width: 990px) {
    background-color: $black;
    color: $white;
    padding-right: 10px;
    padding-left: 10px;
  }
  .footer_content {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    /* color: $white; */
    z-index: 500 !important;
    position: relative;
    display: flex;
    align-items: center;
    .logo {
      img {
        width: 100%;
        @media only screen and (max-width: 1560px) {
          padding-bottom: 30px;
        }

      }
    }
    @media only screen and (max-width: 1560px) {
      /* gap: 30px; */
      flex-direction: column;
    }
    @media only screen and (max-width: 990px) {
      /* s */
      .logo {
        display: flex;
        justify-content: center ;
        padding-bottom: 30px;
        filter: invert(100);
        img {
          width: 150px !important;
        }
      }
    }

    padding: 40px;
    @media only screen and (max-width: 700px) {
      padding-left: 0px;
      padding-right: 0px;
    }

    @media only screen and (max-width: 990px) {
      padding-bottom: 10px;
      .logo {
        width: 100%;
        img {
          width: 50%;
        }
      }
      flex-direction: column;
      .footer_content_layout {
        padding-left: 0px !important;
        flex-direction: column;
        .contact_wrap {
          .info {
            flex-direction: column;
            gap: 20px;
          }
        }
      }
    }
    margin-top: 0px;
    .footer_content_layout {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-left: 40px;

      @media only screen and (max-width: 1560px) {
        /* gap: 30px; */
        flex-direction: column;
        padding-left: 0px;
      }
    }
    .contact_wrap {
      display: flex;
      gap: 60px;
      @media only screen and (max-width: 1800px) {
        gap: 20px;
      }
      @media only screen and (max-width: 1560px) {
        gap: 30px;
        justify-content: center;
        /* flex-direction: column; */
      }
      @media only screen and (max-width: 990px) {
        gap: 30px;
        /* justify-content: center; */
        text-align: center;
        flex-direction: column;
      }
      /* justify-content: space-between; */

      .CTA {
        font-size: 32px;
        font-weight: bold;
        display: flex;
        justify-content: start;
        flex-direction: column;

        .title {
          font-size: 24px;
          font-weight: bold;
          padding-bottom: 15px;
          @media only screen and (max-width: 990px) {
            padding-bottom: 5px;
          }
        }
        .content {
          display: flex;
          gap: 35px;
          @media only screen and (max-width: 990px) {
            gap: 50px;
            padding: 30px 0px;
            justify-content: center;
          }
        }
        @media only screen and (max-width: 990px) {

          flex-direction: column;
        }
        .social {
          &:hover {
            .icon {
              transform: scale(1.1);
              cursor: pointer;
            }
          }
          position: relative;
          display: flex;
          align-items: center;
          .icon {
            transition: 0.3s;
            width: 28px;
            height: 28px;
            color: #6C777F;
            @media only screen and (max-width: 990px) {
              color: #6C777F;
            }
          }
          img {
            @media only screen and (max-width: 990px) {
              filter: invert(20);
            }
            width: 150%;
            position: absolute;
          }
        }
      }
      .info {
        display: flex;
        justify-content: center;
        gap: 100px;
        @media only screen and (max-width: 1800px) {
          gap: 20px;
        }
        .contact_us {
          height: 100%;
          .contact {
            font-weight: 500;
            font-size: 18px;
            padding-bottom: 5px;
            display: flex;
            align-items: center;
            gap: 15px;
            .icon {
              cursor: pointer;
              transition: 0.3s;
              &:hover {
                transform: scale(1.1);
              }
            }
            @media only screen and (max-width: 990px) {
              justify-content: center;
            }
          }
          .title {
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 15px;
            @media only screen and (max-width: 990px) {
              padding-bottom: 5px;
            }
          }
        }

      }
      .owner {
        height: 100%;
        .title {
          font-size: 24px;
          font-weight: bold;
          padding-bottom: 15px;
          @media only screen and (max-width: 990px) {
            padding-bottom: 5px;
          }
        }
        .owner_data {
          font-weight: 500;
          font-size: 18px;
          padding-bottom: 5px;
        }
      }
    }
    .info_section {
      /* background-color: #6C777F; */
      min-width: 500px;
      border-radius: 5px;
      @media only screen and (max-width: 1560px) {
        padding-top: 30px;
      }
      @media only screen and (max-width: 990px) {
        width: 100%;
        min-width: 0px !important;
      }

      .google_maps_section {
        @media only screen and (max-width: 990px) {
          /* padding-top: 30px; */
          width: 100%;
        }
        .title {
          font-size: 24px;
          font-weight: bold;
          padding-bottom: 15px;
        }
      }
    }

  }
  .footer_background {

    @media only screen and (max-width: 990px) {
      display: none;
    }
    /* background-color: $black; */
    position: absolute;
    min-height: 200px;
    z-index: 1 !important;
    width: 100vw;
    left: 0;
  }
}
.scroll_up_button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  @media only screen and (max-width: 990px) {
    bottom: 20px;
    left: 20px;
  }
  z-index: 999 !important;
  background-color: $white;
  border-radius: 12px;
  padding: 5px 10px;
  cursor: pointer;
}
.scroll_up_button_visible {
  animation: show_scroll_button 0s ease;

  @media only screen and (max-width: 990px) {
    animation: show_scroll_button_mobile 0s ease;
  }
  @keyframes show_scroll_button {
    from {
      transform: translateX(200px);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes show_scroll_button_mobile {
    from {
      transform: translateX(-200px);
    }
    to {
      transform: translateX(0);
    }
  }
}
.scroll_up_button_hidden {
  animation: hide_scroll_button 0s ease;
  transform: translateX(200px);

  @media only screen and (max-width: 990px) {
    transform: translateX(-200px);
    animation: hide_scroll_button_mobile 0s ease;
  }
  @keyframes hide_scroll_button {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(200px);
    }
  }
  @keyframes hide_scroll_button_mobile {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-200px);
    }
  }
}

.about_us_page_content_body {

  min-height: 55vh;
  .about_us_bg {
    background-color: $black;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1 !important;
    /* animation: about_us_bg_animation 1s ease-out;
    @keyframes about_us_bg_animation {
      from {
        max-height: 0px;
      }
      to {
        max-height: 1000px;
      }
    } */
  }
  .about_us_intro {
    background-color: $black;
    position: relative;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* max-width: 750px; */
    padding: 60px 0px;
    margin-left: auto;
    margin-right: auto;
    z-index: 50 !important;
    h1 {
      font-size: 46px;
      max-width: 750px;
    }
    h2 {
      font-weight: 500;
      max-width: 750px;
        padding: 0px 15px;
    }
  }
  .about_us_mission {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    gap: 20px;
    @media only screen and (max-width: 990px) {
      margin-top: 30px;
      flex-direction: column;
      padding: 0px 15px;
    }
    min-height: 50vh;
    align-items: center;
    padding: 0px 40px;
    @media only screen and (max-width: 550px) {
      padding: 0px 20px;
    }
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    .text {
      max-width: 850px;
      @keyframes text_animation {
        from {
          transform: translateX(-1000px);
        }
        to {
          transform: translateX(0px);
        }
      }
      h1 {
        color:  $black;
        animation: text_animation 1s ease;
      }
      p {

        color:  $black;
        animation: text_animation 1.5s ease;
      }
    }
    .images {
      display: flex;
      flex-direction: column;
      gap: 25px;
      padding-bottom: 30px;
      @media only screen and (max-width: 990px) {
        width: 100%;
        gap: 10px;
      }
      .first_row {
        display: flex;
        @media only screen and (max-width: 990px) {
          justify-content: space-between;
        }
        @media only screen and (max-width: 550px) {
          flex-direction: column;
        }
        gap: 25px;
        @media only screen and (max-width: 550px) {
          gap: 10px;
        }
        .project_element_img {
          @media only screen and (max-width: 550px) {
            width: 100%;
          }
          /* background-color: $black; */
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items : center;
          animation: first_image_animation 1s ease;
          &:nth-child(2) {
            animation: first_image_animation 1.5s ease;

          }
          @keyframes first_image_animation {
            from {
              transform: translateX(1000px);
            }
            to {
              transform: translateX(0px);
            }
          }
        }
      }
      .second_row {
        .project_element_img {
          /* background-color: $black; */
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          animation: first_image_animation 1.8s ease;
          @keyframes first_image_animation {
            from {
              transform: translateX(1000px);
            }
            to {
              transform: translateX(0px);
            }
          }
        }
      }
    }
  }
}

.services_card_page_content_body {
  min-height: 55vh;

  .service_card_intro {
    background-color: $black;
    position: relative;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px 0px;
    margin-left: auto;
    margin-right: auto;
    z-index: 50 !important;
    h1 {
      font-size: 46px;
      max-width: 750px;

    }
    h2 {
      font-weight: 500;
      max-width: 750px;
      padding: 0px 15px;
    }
  }
  .service_card_content {
    padding: 0px 0px 50px 0px;
    .service_element_not_available {
      &:hover {
        .img_overlay {
          background-color: rgba($black, 0.3);
        }
        .service_descr {
          width: 380px;
        }
        .service_headline {
          transform: translateY(0px) !important;
        }
      }
    }
    .service_element {
      overflow: hidden;
      max-width: 850px;
      min-height: 400px;
      @media only screen and (max-width: 990px) {
        min-height: 280px;
      }
      margin: 0 auto;
      margin-top: 50px;
      position: relative;
      .service_headline {
        position: absolute;
        overflow: hidden;
        transition: 0.3s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 30px);
        bottom: 15px;
        left: 15px;
        color: $white;
        font-size: 48px;
        @media only screen and (max-width: 990px) {
          font-size: 26px;
          font-weight: bold;
          flex-direction: column;
          text-align: center;
          gap: 20px;
          padding-bottom: 15px;
        }
        font-weight: 500;
        .icon {
          @media only screen and (max-width: 990px) {
            width: 40px;
            height: 40px;
          }
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .service_descr {
        background-color: rgba($color: $black, $alpha: 0.8);
        color: $white;
        transition: 0.3s;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 0px;
        overflow: hidden;
        /* display: flex;
        align-items: center; */
        div {
          padding-left: 15px;
          padding-top: 15px;
          font-size: 22px;
          font-weight: 600;
          white-space: nowrap;
          @media only screen and (max-width: 990px) {
            font-size: 20px;
          }
        }
        ul {
          width: 330px;
          height: 100%;
          padding-right: 10px;
          li {
            text-decoration: none;
            padding-bottom: 20px;
          }
        }
      }

      &:hover {
        .img_overlay {
          background-color: rgba($black, 0.3);;
        }
        .service_descr {
          width: 380px;
        }
        .service_headline {
          transform: translateY(200px);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;

      }
      .icon_not_available {
        position: absolute;
        width: 150px;
        height: 150px;
        top: calc(50% - 75px);
        left: calc(50% - 75px);
        color: #ffffff;
        @media only screen and (max-width: 990px) {
          width: 80px;
          height: 80px;
          top: calc(50% - 50px);
          left: calc(50% - 40px);
        }
      }
      .img_overlay {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        background-color: rgba($black, 0.5);
        transition: 0.3s;
      }
      .img_overlay_not_available {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        background-color: rgba($black, 0.8);
      }
    }
  }
}

.blog_main_wrap {
  min-height: 50dvh;
  margin-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .blog_new_post {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    position: relative;
    .blog_new_post_content {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.563);
      padding: 15px 15px;
      bottom: 0;
    }
    img {
      width: 100%;
      aspect-ratio: 2 / 1 ;
      object-fit: cover;
      filter: brightness(50%)
    }
    h1 {
      color: $black;
    }
    p {
      color: $black;
    }
    a {
      font-size: 16px;
      color: $black;
      font-weight: bold;
      white-space: nowrap;
    }
  }
  @media only screen and (max-width: 600px) {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .blog_element {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    display: flex;
    gap: 10px;
    align-items: end;
    .blog_content {
      display: flex;
      height: 100%;
      flex-direction: column;
      /* justify-content: space-between; */
    }
    .blog_img {
      img {
        width: 300px;
        aspect-ratio: 2 / 1;
        object-fit: cover;
      }
    }
    h1 {
      font-size: 24px;
      color: $black;
      margin: 0;
      @media only screen and (max-width: 600px) {
        font-size: 20px;
      }
    }
    .blog_cta {
      display: flex;
      align-items: end;

      p {
        font-size: 16px;
        color: #393d41;
        padding: 0;
        margin: 0;
      }
      a {
        font-size: 16px;
        color: $black;
        font-weight: bold;
        white-space: nowrap;
      }

      @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        p, a {
          font-size: 14px;
          padding-top: 10px;
        }
      }
    }
  }
}